import * as Chart from "./Chart";

export enum TimePeriod {
  Hour = "1h",
  Day = "24h",
  Week = "7d",
}

export enum ChartID {
  QueueJobPassRateSparkline = "queue_jobs_pass_rate_sparkline",
  QueueJobPassRate = "queue_jobs_pass_rate",
  QueueAgentUtilizationSparkline = "queue_agent_utilization_sparkline",
  QueueJobsWaitingSparkline = "queue_jobs_waiting_sparkline",
  QueueAgentUtilization = "queue_agent_utilization",
  ConnectedAgents = "connected_agents",
  RunningJobs = "running_jobs",
  QueueJobsWaiting = "queue_jobs_waiting",
  QueueWaitTimeSparkline = "queue_wait_time_sparkline",
  QueueWaitTime = "queue_wait_time",
}

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  timePeriod: TimePeriod;
  id: ChartID;
};

export default function Renderer({ id, data, timePeriod }: Props) {
  switch (id) {
    case ChartID.QueueWaitTimeSparkline:
      return <Chart.QueueWaitTimeSpark {...data} />;
    case ChartID.QueueWaitTime:
      return <Chart.QueueWaitTimeDetail {...data} timePeriod={timePeriod} />;
    case ChartID.QueueJobsWaitingSparkline:
      return <Chart.QueueJobsWaitingSpark {...data} />;
    case ChartID.QueueJobsWaiting:
      return <Chart.QueueJobsWaitingDetail {...data} timePeriod={timePeriod} />;
    case ChartID.QueueAgentUtilizationSparkline:
      return <Chart.QueueAgentUtilizationSpark {...data} />;
    case ChartID.QueueAgentUtilization:
      return (
        <Chart.QueueAgentUtilizationDetail {...data} timePeriod={timePeriod} />
      );
    case ChartID.ConnectedAgents:
      return <Chart.ConnectedAgents {...data} timePeriod={timePeriod} />;
    case ChartID.RunningJobs:
      return <Chart.RunningJobs {...data} timePeriod={timePeriod} />;
    case ChartID.QueueJobPassRateSparkline:
      return <Chart.JobPassRateSpark {...data} timePeriod={timePeriod} />;
    case ChartID.QueueJobPassRate:
      return <Chart.JobPassRateDetail {...data} timePeriod={timePeriod} />;
    default:
      throw new Error(`Unknown chart type: ${id}`);
  }
}
