import Icon from "app/components/shared/Icon";
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
  HoverCardPortal,
} from "app/components/shared/HoverCard";
import Badge from "app/components/shared/Badge";
import linuxLogo from "app/images/compute/machine-type-linux.png";
import macosLogo from "app/images/compute/machine-type-macos.png";
import windowsLogo from "app/images/compute/machine-type-windows.png";

const MACHINE_TYPES = {
  linux: {
    value: "linux",
    label: "Linux",
    logo: linuxLogo,
  },
  macos: {
    value: "macos",
    label: "MacOS",
    logo: macosLogo,
  },
  windows: {
    value: "windows",
    label: "Windows",
    logo: windowsLogo,
  },
};

export default function Agent({
  agentName,
  agentPath,
  queueName,
  agentsPath,
  onNestedLinkTrigger,
  hosted,
  instanceShape,
  hostedConcurrency,
  hostedOSVersion,
}: {
  agentName: string;
  agentPath: string;
  queueName?: string;
  agentsPath: string;
  onNestedLinkTrigger: (e: React.MouseEvent | React.KeyboardEvent) => void;

  hosted?: boolean;
  instanceShape?: {
    cpu: string;
    memory: string;
    machineType: string;
    architecture: string;
    osVersion: string;
    operatingSystem: string;
  };
  hostedConcurrency?: {
    runningJobsCount: number;
    maximum: number;
  };
  hostedOSVersion?: string;
}) {
  const agentLink = (
    <a href={agentPath} className="text-inherit hover:text-inherit truncate">
      {agentName}
    </a>
  );

  const showHoverCard =
    !window.Features.BuildsShowAgentNameOnJobRow || queueName;

  const showHostedAgentDetails =
    window.Features.ShowHostedAgentInJobRow && hosted;

  return (
    <HoverCard>
      <HoverCardTrigger asChild={true}>
        <a
          href={agentPath}
          className="job-stats flex flex-auto items-center gap-x-1"
          onClick={onNestedLinkTrigger}
          onKeyDown={onNestedLinkTrigger}
        >
          {showHostedAgentDetails && instanceShape?.operatingSystem ? (
            <Badge className="max-w-[240px] min-w-[70px] badge badge--outline flex items-center gapx0.5 justify-center">
              <img
                src={MACHINE_TYPES[instanceShape?.operatingSystem].logo}
                width="14"
                height="14"
                style={{ verticalAlign: "text-top" }}
              />
              Hosted
            </Badge>
          ) : (
            <Badge className="max-w-[240px] min-w-[70px] badge badge--outline flex items-center gapx0.5 justify-center">
              <Icon
                icon="custom/outline/14x/agent"
                className="flex-shrink-0 w-3.5 h-3.5"
              />
              {window.Features.BuildsShowAgentNameOnJobRow ? (
                <span className="truncate max-w-[240px]">{agentName}</span>
              ) : (
                "Agent"
              )}
            </Badge>
          )}
        </a>
      </HoverCardTrigger>

      {showHoverCard && (
        <HoverCardContent
          size="max-lg"
          onClick={onNestedLinkTrigger}
          onKeyDown={onNestedLinkTrigger}
        >
          {queueName ? (
            <div className="grid gap-x-2.5 gap-y-1.5 grid-cols-[1fr_2fr]">
              <span className="font-semibold">Agent</span>

              {agentLink}

              <span className="font-semibold">Queue</span>

              <a
                href={agentsPath}
                className="text-inherit hover:text-inherit truncate"
              >
                {queueName}
              </a>
              {showHostedAgentDetails && instanceShape && (
                <>
                  <span className="font-semibold">OS</span>
                  <div>
                    <Badge className="charcoal-300 bg-gray-100">
                      <img
                        src={MACHINE_TYPES[instanceShape?.operatingSystem].logo}
                        width="14"
                        height="14"
                        style={{ verticalAlign: "text-top" }}
                      />
                      {MACHINE_TYPES[instanceShape?.operatingSystem].label}{" "}
                      {hostedOSVersion}
                    </Badge>
                  </div>
                  <span className="font-semibold">Architecture</span>
                  <div>
                    <Badge className="charcoal-300 bg-gray-100">
                      {instanceShape.architecture}
                    </Badge>
                  </div>
                  <span className="font-semibold">System</span>
                  <div>
                    <Badge className="charcoal-300 bg-gray-100">
                      {instanceShape.cpu} vCPU · {instanceShape.memory} GB RAM
                    </Badge>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="flex">{agentLink}</div>
          )}
        </HoverCardContent>
      )}
    </HoverCard>
  );
}
