/* eslint-disable id-length */
import { Suspense, useEffect } from "react";
import { CachePolicies, useFetch } from "use-http";
import Spinner from "app/components/shared/Spinner";
import { LoadingChart } from "app/components/analytics/shared/LoadingStates";
import classNames from "classnames";
import Renderer, { ChartID, TimePeriod } from "./Renderer";
import Icon from "../shared/Icon";
import Button from "../shared/Button";

enum ChartSize {
  Small = "small",
  Large = "large",
}

interface Props {
  size: ChartSize;
  id: ChartID;
  endpoint: string;
  timePeriod: TimePeriod;
  pollInterval?: number;
}

const DEFAULT_POLL_INTERVAL_SECONDS = 10;

const AsyncChart = ({
  id,
  endpoint,
  pollInterval = DEFAULT_POLL_INTERVAL_SECONDS,
  ...props
}: Props) => {
  const {
    data,
    error,
    get: fetchChartData,
  } = useFetch(endpoint, {
    suspense: true,
    cachePolicy: CachePolicies.NETWORK_ONLY,
  });

  useEffect(() => {
    fetchChartData();

    const interval = setInterval(() => {
      fetchChartData();
    }, pollInterval * 1000);

    return () => clearInterval(interval);
  }, []);

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-full gap-2">
        <div>There was an error loading the chart</div>
        <Button
          onClick={() => fetchChartData()}
          theme="small"
          className="flex gap-1"
        >
          <Icon icon="custom/outline/16x/arrows-refresh" className="w-4 h-4" />
          <span>Retry</span>
        </Button>
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return <Renderer {...props} id={id} data={data} />;
};

const ChartLoader = (props: Props) => {
  return (
    <div
      className={classNames({
        "h-40": props.size === "small",
        "h-full min-h-[18rem] lg:h-[18rem]": props.size === "large",
      })}
    >
      <Suspense fallback={<LoadingState size={props.size} />}>
        <AsyncChart {...props} />
      </Suspense>
    </div>
  );
};

const LoadingState = ({ size }: { size: ChartSize }) => {
  if (size === ChartSize.Large) {
    return (
      <div className="flex items-center justify-center h-full">
        <LoadingChart barWidth="25px" />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center h-full">
      <Spinner color={false} />
    </div>
  );
};

export default ChartLoader;
