import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "app/components/shared/HoverCard";
import { formatTooltipDateString } from "./Chart/utils";

export function Tooltip({ tooltip, children }) {
  const dateString = formatTooltipDateString(tooltip.dataPoints[0].parsed.x);

  return (
    <HoverCard open={true}>
      <HoverCardTrigger />
      <HoverCardContent
        side="right"
        className="w-auto px-2 py-2.5 grid gap-x-3 gap-y-0.5 grid-cols-[auto_auto] text-xs"
      >
        <div className="font-semibold  col-span-2">{dateString}</div>
        {children}
      </HoverCardContent>
    </HoverCard>
  );
}

export function TooltipLabel({
  children,
  color,
}: React.HTMLAttributes<HTMLSpanElement> & { color?: string }) {
  return (
    <span className="font-semibold text-charcoal-300" style={{ color }}>
      {children}
    </span>
  );
}

export function TooltipValue({
  children,
}: React.HTMLAttributes<HTMLSpanElement>) {
  return <span className="text-right ">{children}</span>;
}
