import { Series, SeriesData } from "app/components/job/Job/HostedCompute/types";
import { ChartDataset } from "chart.js";

export const convertToChartDatasets = (data: Series): ChartDataset[] => {
  return Object.keys(data)
    .filter((key) => key !== "timestamps")
    .map((key) => ({
      label: key,
      data: (data[key] as SeriesData).values,
    }));
};
