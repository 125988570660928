import { Job } from "app/stores/BuildShowStore";
import Icon from "app/components/shared/Icon";
import { SortFn } from ".";

function getQueueIcon(job: Job) {
  if (job.hosted) {
    return "heroicons/16/solid/cloud";
  }
  return "heroicons/16/solid/server-stack";
}

export function Queue({ job }: { job: Job }) {
  if (!job.clusterQueue) {
    return null;
  }

  return (
    <div className="flex items-center gap-1">
      <Icon icon={getQueueIcon(job)} className="w-4 h-4 block shrink-0" />
      <a
        href={job.agentsPath}
        // eslint-disable-next-line react/jsx-no-bind, id-length
        onClick={(e) => e.stopPropagation()}
        className="text-inherit hover:text-inherit focus:text-inherit truncate"
      >
        {job.clusterQueue.key}
      </a>
    </div>
  );
}

// eslint-disable-next-line id-length
export const sortByQueue: SortFn = (direction) => (a, b) => {
  const aQueue = a.clusterQueue?.key || "";
  const bQueue = b.clusterQueue?.key || "";

  if (direction === "asc") {
    return aQueue.localeCompare(bQueue);
  }
  return bQueue.localeCompare(aQueue);
};
