import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export enum DockPosition {
  Right = "right",
  Bottom = "bottom",
  Center = "center",
}

const SIDEBAR_DEFAULT_WIDTH = 320;
const SIDEBAR_MAX_WIDTH = 440;
const SIDEBAR_MIN_WIDTH = 200;

interface BuildPreferences {
  dockPosition: DockPosition;
  setDockPosition: (dockPosition: DockPosition) => void;

  /**
   * Resized sidebar width (as pixels)
   */
  sidebarWidth?: number;
  setSidebarWidth: (width: number) => void;

  /**
   * Resized drawer height (in percentage)
   */
  resizedDrawerHeight?: number;
  setResizedDrawerHeight: (height: number) => void;

  /**
   * Resized drawer width (in percentage)
   */
  resizedDrawerWidth?: number;
  setResizedDrawerWidth: (width: number) => void;

  sortColumn?: string | null;
  setSortColumn: (column: string | null) => void;

  sortDirection?: "asc" | "desc" | null;
  setSortDirection: (direction: "asc" | "desc" | null) => void;
}

/**
 * A store for build page preferences persisted into local storage to ensure
 * the user's preferences are remembered between sessions.
 */
export const useBuildPreferencesStore = create<BuildPreferences>()(
  persist(
    (set) => ({
      dockPosition: DockPosition.Bottom,
      resizedDrawerHeight: 50,
      resizedDrawerWidth: 50,
      sidebarWidth: SIDEBAR_DEFAULT_WIDTH,

      setSortColumn: (column: string | null) =>
        set(() => ({ sortColumn: column })),
      setSortDirection: (direction: "asc" | "desc" | null) =>
        set(() => ({ sortDirection: direction })),

      setSidebarWidth: (width: number) => {
        if (width > SIDEBAR_MAX_WIDTH) {
          width = SIDEBAR_MAX_WIDTH;
        } else if (width < SIDEBAR_MIN_WIDTH) {
          width = SIDEBAR_MIN_WIDTH;
        }

        return set(() => ({ sidebarWidth: width }));
      },

      setDockPosition: (dock: DockPosition) =>
        set(() => ({ dockPosition: dock })),

      setResizedDrawerHeight: (height: number) =>
        set(() => ({ resizedDrawerHeight: height })),

      setResizedDrawerWidth: (width: number) =>
        set(() => ({ resizedDrawerWidth: width })),
    }),
    {
      name: "build-preferences",
      storage: createJSONStorage(() => localStorage),
      version: 1,
    },
  ),
);
