import { Job } from "app/stores/BuildShowStore";
import { SortFn } from ".";
import Icon from "app/components/shared/Icon";

export function Agent({ job }: { job: Job }) {
  if (!job.agentPath) {
    return null;
  }

  return (
    <div className="flex items-center gap-1">
      <Icon
        icon="custom/outline/14x/agent"
        className="flex-shrink-0 w-3.5 h-3.5"
      />
      <a
        href={job.agentPath}
        // eslint-disable-next-line react/jsx-no-bind, id-length
        onClick={(e) => e.stopPropagation()}
        className="text-inherit hover:text-inherit focus:text-inherit truncate"
      >
        {job.agentName}
      </a>
    </div>
  );
}

// eslint-disable-next-line id-length
export const sortByAgent: SortFn = (direction) => (a, b) => {
  if (direction === "asc") {
    return b.agentName?.localeCompare(a.agentName);
  }
  return a.agentName?.localeCompare(b.agentName);
};
