import { twMerge } from "tailwind-merge";

export function LegendIndicator({
  color,
  className,
  selected = true,
  ...props
}: React.HTMLAttributes<HTMLSpanElement> & {
  color: string;
  selected: boolean;
}) {
  return (
    <span
      style={{ color }}
      className={twMerge(
        "inline-block w-2 h-2 rounded-[2px] ring-1 ring-slate-500 ring-offset-2 mx-[3px]",
        selected && "bg-current ring-current",
        className,
      )}
      {...props}
    />
  );
}
