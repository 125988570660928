/* eslint-disable id-length */

import { ChartDataset, TooltipModel } from "chart.js";
import { take } from "lodash";
import { Fragment, useEffect } from "react";

import { useChart } from "app/components/shared/Chart/chart";
import LineChart, { LineChartUnit } from "../LineChart";
import { TimePeriod } from "../Renderer";
import { ThemeNegativeTrend, ThemeDefaultTrend } from "../Theme";
import { Tooltip, TooltipLabel, TooltipValue } from "../Tooltip";
import { ChartLegend } from "../ChartLegend";
import {
  Card,
  CardSection,
  CardChart,
  CardLabel,
  CardDelta,
  CardStat,
} from "../Card";
import SparkLineChart, { dataForSparkline } from "../SparkLineChart";
import {
  calculateAverage,
  calculateMin,
  calculateMax,
  formatDuration,
  getTimePeriodString,
  labelHeader,
  Scope,
  getThemeFromDelta,
} from "./utils";
import { useChartData } from "./hooks";

interface DataPoint {
  x: number;
  y: number;
}

export function QueueWaitTimeSpark({
  datasets: { current, previous },
  p95,
}: {
  datasets: {
    current: DataPoint[];
    previous: DataPoint[];
  };
  p95: number;
}) {
  const currentAvg = calculateAverage(current);
  const previousAvg = calculateAverage(previous);

  const delta = currentAvg - previousAvg;
  const theme = getThemeFromDelta(delta, true);

  return (
    <Card>
      <CardSection>
        <CardLabel>Queue wait time</CardLabel>
        {!!delta && (
          <CardDelta
            formattedValue={formatDuration(Math.abs(delta))}
            value={delta}
            color={theme.color}
          />
        )}
      </CardSection>

      <CardSection>
        <CardStat
          value={formatDuration(currentAvg)}
          label="Avg"
          size="lg"
          layout="stacked"
        />
        <CardStat value={formatDuration(p95)} label="p95" />
      </CardSection>

      <CardChart>
        <SparkLineChart
          data={dataForSparkline(previous, current, "y", theme)}
        />
      </CardChart>
    </Card>
  );
}

export function QueueWaitTimeDetail({
  dataset,
  timePeriod,
  scope,
}: {
  timePeriod: TimePeriod;
  scope: Scope;
  dataset: Array<ChartDataset<"line", DataPoint[]> & { delta: number }>;
}) {
  const { chartRef, updateData } = useChart<"line", DataPoint>();

  const data = useChartData({
    datasets: dataset,
    orderDatasetBy: ({ data }) => calculateAverage(data),
  });

  useEffect(() => {
    updateData(data);
  }, [data, updateData]);

  return (
    <Card className="lg:flex-row">
      <div className="flex flex-col grow gap-3 min-w-0">
        <CardSection className="justify-start">
          <CardLabel description={`${getTimePeriodString(timePeriod)}`}>
            Queue wait time
          </CardLabel>
        </CardSection>

        <CardChart>
          <LineChart
            data={data}
            ref={chartRef}
            tooltip={ChartTooltip}
            units={{ y: LineChartUnit.duration }}
          />
        </CardChart>
      </div>

      <ChartLegend
        labelHeader={labelHeader(scope, "Queue")}
        datasets={data.datasets}
        chartRef={chartRef}
        columns={{
          Avg: ({ data, delta }, _index, visible) => {
            const avg = formatDuration(calculateAverage(data));

            const theme = getThemeFromDelta(delta, true);

            return (
              <>
                {avg}
                {!!delta && (
                  <CardDelta
                    className="ml-1 text-[11px]"
                    formattedValue={formatDuration(Math.abs(delta))}
                    value={delta}
                    color={visible ? theme.color : "#c2cace"}
                  />
                )}
              </>
            );
          },
          Min: ({ data }) => formatDuration(calculateMin(data)),
          Max: ({ data }) => formatDuration(calculateMax(data)),
        }}
      />
    </Card>
  );
}

const ChartTooltip = ({ tooltip }: { tooltip: TooltipModel<"line"> }) => {
  const visibleDataPoints = take(tooltip.dataPoints, 8);
  const hiddenDataPoints = tooltip.dataPoints.length - 8;

  return (
    <Tooltip tooltip={tooltip}>
      {visibleDataPoints.map(({ dataset, parsed }) => (
        <Fragment key={dataset.label}>
          <TooltipLabel color={dataset.borderColor as string}>
            {dataset.label}
          </TooltipLabel>
          <TooltipValue>{formatDuration(parsed.y)}</TooltipValue>
        </Fragment>
      ))}

      {hiddenDataPoints > 0 && (
        <span className="col-span-2 text-[11px] font-medium text-charcoal-300">
          ({hiddenDataPoints} not shown)
        </span>
      )}
    </Tooltip>
  );
};
