import { useCallback } from "react";
import moment from "moment";
import styled, { css } from "styled-components";
import Tooltip from "app/components/analytics/shared/Tooltips/BasicTooltip";

export type DateFilter = "7days" | "14days" | "28days";

type Props = {
  setFilter: (DateFilter) => void;
  filter: DateFilter;
  heading?: string;
  subHeading?: string;
  className?: string;
  tooltip?: {
    name: string;
    description: string;
  };
  hideDateFilter?: boolean;
};

const StyledFilters = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: 487px) {
    align-items: center;
  }

  @media (max-width: 486px) {
    flex-direction: column;
    align-items: left;
  }
`;

const FilterButton = styled.button<{ isActive: boolean }>`
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0.5em 0.75em;
  height: auto;
  vertical-align: middle;
  -webkit-appearance: none;
  background-color: transparent;
  color: ${({ isActive }) =>
    isActive ? "var(--charcoal-700)" : "var(--gray-800)"};
  border: ${({ isActive }) =>
    isActive ? `1px solid var(--gray-800)` : "1px solid transparent"};
  border-radius: 4px;
  white-space: nowrap;
  font-weight: ${({ isActive }) => (isActive ? "600" : "400")};
  ${({ isActive }) =>
    !isActive &&
    css`
      &:focus,
      &:hover {
        color: var(--charcoal-700);
        border: 1px solid var(--gray-800);
        font-weight: 400;
      }
    `}

  &::after {
    content: attr(title);
    font-weight: 600;
    display: block;
    height: 0;
    color: transparent;
  }
`;

export const LAST_7_DAYS = {
  label: "Last 7 days",
  from: moment().subtract(6, "days").utc(),
};
export const LAST_14_DAYS = {
  label: "Last 14 days",
  from: moment().subtract(13, "days").utc(),
};
export const LAST_28_DAYS = {
  label: "Last 28 days",
  from: moment().subtract(27, "days").utc(),
};

export const DATE_FILTERS = [
  { label: "Last 7 days", value: "7days" },
  { label: "Last 14 days", value: "14days" },
  { label: "Last 28 days", value: "28days" },
];

const DateFilters = ({
  className,
  heading,
  subHeading,
  tooltip,
  filter,
  hideDateFilter,
  setFilter,
}: Props) => (
  <StyledFilters className={className}>
    {heading && <h2 className="mt-4 charcoal-700 text-lg">{heading}</h2>}

    {subHeading && (
      <div className="flex items-center">
        <h3 className="charcoal-700 text-sm">{subHeading}</h3>
        {tooltip && (
          <Tooltip classNames="ml-2" name={tooltip.name}>
            <>
              <p className="font-semibold m-0">{subHeading}</p>
              <p
                className="m-0 mt-1"
                dangerouslySetInnerHTML={{ __html: tooltip.description }}
              />
            </>
          </Tooltip>
        )}
      </div>
    )}

    {!hideDateFilter && (
      <div>
        {DATE_FILTERS.map((dateFilter) => {
          const isActive = filter === dateFilter.value;
          const handleClick = useCallback(
            () => setFilter(dateFilter.value),
            [],
          );

          return (
            <FilterButton
              className="m1"
              key={dateFilter.value}
              isActive={isActive}
              onClick={handleClick}
              title={dateFilter.label}
            >
              {dateFilter.label}
            </FilterButton>
          );
        })}
      </div>
    )}
  </StyledFilters>
);

export default DateFilters;
