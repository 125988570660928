/* eslint-disable id-length */
import { Card, CardSection, CardChart, CardLabel } from "../Card";
import LineChart from "../LineChart";
import { useChart } from "app/components/shared/Chart/chart";
import { TimePeriod } from "../Renderer";
import { TooltipModel } from "chart.js";
import { Tooltip, TooltipLabel, TooltipValue } from "../Tooltip";
import { Fragment, useEffect } from "react";
import {
  calculateMin,
  calculateMax,
  getLatestValue,
  getTimePeriodString,
  calculateSum,
  Scope,
} from "./utils";
import { ChartLegend } from "../ChartLegend";
import { useChartData } from "./hooks";
import { take } from "lodash";

const chartOptionsForScope = (scope: Scope) => {
  const baseOptions = { parsing: { yAxisKey: "active", xAxisKey: "x" } };

  switch (scope) {
    case Scope.Organization:
    case Scope.Cluster:
      return {
        ...baseOptions,
        datasets: {
          line: {
            fill: true,
          },
        },
        scales: {
          y: {
            stacked: true,
          },
        },
      };
    default:
      return baseOptions;
  }
};

interface DataPoint {
  x: number;
  active: number;
  percentage: number;
  running_jobs: number;
}

interface Props {
  scope: Scope;
  dataset: {
    label: string;
    data: DataPoint[];
  }[];
  timePeriod: TimePeriod;
}

export function ConnectedAgents({ scope, dataset, timePeriod }: Props) {
  const { chartRef, updateData } = useChart<"line", DataPoint>();

  const data = useChartData({
    datasets: dataset,
    orderDatasetBy: ({ data }) => calculateSum(data, "active"),
    colorsOffset: 2,
  });

  useEffect(() => {
    updateData(data);
  }, [data, updateData]);

  return (
    <Card className="lg:flex-row">
      <div className="flex flex-col grow gap-3 min-w-0">
        <CardSection>
          <CardLabel description={getTimePeriodString(timePeriod)}>
            Active agents
          </CardLabel>
        </CardSection>

        <CardChart>
          <LineChart
            ref={chartRef}
            data={data}
            tooltip={ChartTooltip}
            options={chartOptionsForScope(scope)}
          />
        </CardChart>
      </div>
      <ChartLegend
        datasets={data.datasets}
        chartRef={chartRef}
        columns={{
          Min: (_, index) => calculateMin(dataset[index].data, "active"),
          Max: (_, index) => calculateMax(dataset[index].data, "active"),
          "Active agents": (_, index) =>
            getLatestValue(dataset[index].data, "active"),
        }}
      />
    </Card>
  );
}

const ChartTooltip = ({ tooltip }: { tooltip: TooltipModel<"line"> }) => {
  const totalActive = calculateSum(
    tooltip.dataPoints.map(({ parsed }) => parsed),
  );

  const visibleDataPoints = take(tooltip.dataPoints, 8);
  const hiddenDataPoints = tooltip.dataPoints.length - 8;

  return (
    <Tooltip tooltip={tooltip}>
      {visibleDataPoints.map(({ dataset, parsed }) => (
        <Fragment key={dataset.label}>
          <TooltipLabel color={dataset.borderColor as string}>
            {dataset.label}
          </TooltipLabel>
          <TooltipValue>{parsed.y}</TooltipValue>
        </Fragment>
      ))}

      <TooltipLabel>Total</TooltipLabel>
      <TooltipValue>{totalActive}</TooltipValue>

      {hiddenDataPoints > 0 && (
        <span className="col-span-2 text-[11px] font-medium text-charcoal-300">
          ({hiddenDataPoints} not shown)
        </span>
      )}
    </Tooltip>
  );
};
