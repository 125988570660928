import { CachePolicies, useFetch } from "use-http";
import { useCallback, useEffect, useState } from "react";
import { Result } from "./types";
import { PercentageComputeChart } from "./PercentageComputeChart";
import { MemoryComputeChart } from "./MemoryComputeChart";
import Spinner from "app/components/shared/Spinner";

const TERMINAL_JOB_STATES = ["finished", "timed_out", "canceled", "expired"];
const REFRESH_INTERVAL = 10000;

const refresh_jitter = () => Math.floor(Math.random() * 1000);

interface InstanceInfoValue {
  instanceInfo: Result | undefined;
  setInstanceInfo: React.Dispatch<React.SetStateAction<Result | undefined>>;
}

interface HostedComputeProps {
  job: {
    hostedAgentMetricsPath: string;
    hostedAgentInfoPath: string;
    state: string;
    agentName: string;
    instanceShape?: {
      operatingSystem: string;
    };
  };
}

export const HostedCompute: React.FC<HostedComputeProps> = (props) => {
  return (
    <div data-testid="JobComputeMetricsComponent" className="relative pb-2">
      {Features.MdcShowHostedAgentInstanceInfo && (
        <ComputeInfo job={props.job} />
      )}
      <ComputeMetrics job={props.job} />
    </div>
  );
};

interface ComputeMetricsProps {
  job: {
    hostedAgentMetricsPath: string;
    state: string;
    instanceShape?: {
      operatingSystem: string;
    };
  };
  callback?: () => void;
}

export const ComputeMetrics: React.FC<ComputeMetricsProps> = (props) => {
  const metrics = [
    { key: "cpu_breakdown", title: "CPU Breakdown" },
    { key: "mem", title: "Memory Utilization" },
    { key: "storage", title: "Storage Utilization" },
    ...(props.job.instanceShape?.operatingSystem?.toLowerCase() === "linux"
      ? [{ key: "io_wait", title: "IO Wait" }]
      : []),
  ];

  const hostedAgentMetricsDataEndpoint =
    props.job.hostedAgentMetricsPath + ".json";

  const { get, response, loading, error } = useFetch(
    hostedAgentMetricsDataEndpoint,
    {
      retries: 3,
      retryDelay: 10000,
      cacheLife: 10000, // 10 seconds
    },
    [],
  );
  const [chartData, setChartData] = useState<Result>();

  const loadInitialChartData = useCallback(async () => {
    const data: Result = await get();
    if (response.ok) {
      setChartData(data);
    }
  }, [hostedAgentMetricsDataEndpoint, get]);

  useEffect(() => {
    loadInitialChartData();
  }, [loadInitialChartData]);

  const refreshData = async () => {
    if (loading) {
      return;
    } // don't refresh if we're already loading

    const data: Result = await get();
    if (response.ok) {
      setChartData(data);
    }
  };

  useEffect(() => {
    if (TERMINAL_JOB_STATES.includes(props.job.state)) {
      return;
    }
    const timerId = setInterval(
      refreshData,
      REFRESH_INTERVAL + refresh_jitter(),
    );
    return () => clearInterval(timerId);
  });

  if (error) {
    return <div>There was an error loading the chart</div>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 mb-4">
      {metrics.map((metric) => (
        <div
          key={metric.key}
          className="flex flex-col items-center justify-center gap-2 border-gray border p-4"
        >
          <div>
            <h3 className="semi-bold text-primary-charcoal dark:text-primary-light">
              {metric.title}
            </h3>
          </div>
          <div className="flex w-full items-center justify-center h-72">
            {!chartData ? <Spinner /> : null}
            {chartData &&
              chartData.series[metric.key] &&
              (metric.key === "mem" ? (
                <MemoryComputeChart
                  height={100}
                  chartData={chartData.series[metric.key]}
                />
              ) : (
                <PercentageComputeChart
                  height={100}
                  chartData={chartData.series[metric.key]}
                />
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

interface ComputeInfoProps {
  job: {
    hostedAgentInfoPath: string;
    agentName: string;
    state: string;
  };
  callback?: () => void;
}

/**
 * Component to display the compute information of the hosted agent
 *
 * @NOTE: This component is only available for customers in the POC stage which need to know the compute information of the hosted agent
 */
export const ComputeInfo: React.FC<ComputeInfoProps> = (props) => {
  const hostedAgentInfoDataEndpoint = props.job.hostedAgentInfoPath + ".json";

  const { get, response, loading, error } = useFetch(
    hostedAgentInfoDataEndpoint,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    },
    [],
  );

  const [instanceInfo, setInstanceInfo] = useState<Result>();

  const fetchComputeInfo = useCallback(async () => {
    const data: Result = await get();
    if (response.ok) {
      setInstanceInfo(data);
    }
  }, [hostedAgentInfoDataEndpoint, get]);

  useEffect(() => {
    fetchComputeInfo();
  }, [fetchComputeInfo]);

  const fetchData = async () => {
    const data: Result = await get();
    if (response.ok) {
      setInstanceInfo(data);
    }
  };

  if (error) {
    return <div>There was an error loading the chart</div>;
  }

  return (
    <div>
      <div>
        {loading ? (
          <div className="flex w-full justify-center border-gray border p-4">
            <div className="w-full h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4" />
          </div>
        ) : null}
        {!loading && (
          <div className="flex flex-wrap items-center justify-between gap-2">
            <div className="border-gray p-4">
              <span className="h4 semi-bold">Continent:</span>{" "}
              {instanceInfo?.instance?.geo_continent}
            </div>
            <div className="border-gray p-4">
              <span className="h4 semi-bold">Platform:</span>{" "}
              {instanceInfo?.instance?.major_hw_platform}
            </div>
            <div className="border-gray p-4">
              <span className="h4 semi-bold">Architecture:</span>{" "}
              {instanceInfo?.instance?.machine_arch}
            </div>
            <div className="border-gray p-4">
              <span className="h4 semi-bold">Memory:</span>{" "}
              {instanceInfo?.instance?.memory_megabytes}
            </div>
            <div className="border-gray p-4">
              <span className="h4 semi-bold">Operating System:</span>{" "}
              {instanceInfo?.instance?.os}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
