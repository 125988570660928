import reactRenderer from "app/lib/reactRenderer";
import { Chart, TooltipModel } from "chart.js";
import React from "react";

export const verticalHoverLine = {
  id: "verticalHoverLine",
  afterDatasetsDraw: function (chart: Chart) {
    const { ctx, chartArea } = chart;

    ctx.save();

    if (!chart.getVisibleDatasetCount()) {
      return;
    }

    chart.getSortedVisibleDatasetMetas()[0].data.forEach((datapoint) => {
      if (datapoint.active) {
        ctx.beginPath();
        ctx.strokeStyle = "#BBBBBB";
        ctx.lineWidth = 1;
        ctx.moveTo(datapoint.x, chartArea.top - 12);
        ctx.lineTo(datapoint.x, chartArea.bottom);
        ctx.stroke();
      }
    });
  },
};

function findOrCreateTooltip(canvas: HTMLCanvasElement) {
  let tooltipEl =
    canvas?.parentElement?.querySelector<HTMLDivElement>("#chartjs-tooltip");

  if (canvas == null) {
    return null;
  }

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.className =
      "pointer-events-none absolute top-12 left-0 duration-100 will-change";

    canvas.parentElement?.appendChild(tooltipEl);
  }

  return tooltipEl;
}

export function createTooltipElement(
  TooltipComponent: React.FC<{ tooltip: TooltipModel<"line"> }>,
) {
  const renderTooltip = ({ tooltip, chart }) => {
    const tooltipEl = findOrCreateTooltip(chart.canvas);

    if (tooltipEl == null) {
      return;
    }

    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = "0";
      return;
    }

    tooltipEl.style.opacity = "1";
    tooltipEl.style.zIndex = "1";
    tooltipEl.style.transform = `translate(${tooltip.caretX}px,0)`;

    reactRenderer(
      React.createElement(TooltipComponent, { tooltip }),
      tooltipEl,
    );
  };

  return renderTooltip;
}
