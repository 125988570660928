import CopyButton from "app/components/shared/CopyButton";
import { RowTest } from ".";

type Props = {
  test: RowTest;
};

const Row = ({ test }: Props) => (
  <li className="row-link__container">
    <div className="ta-panel-row grid-cols-[auto_6rem_8rem]">
      <div className="main-section">
        {test.state && (
          <span className="badge bg-gray-300 charcoal-800 semi-bold mr-2">
            {test.state}
          </span>
        )}
        <a
          href={test.url}
          className="block font-medium text-inherit no-underline hover:underline focus:underline truncate"
        >
          {test.scope} {test.name}
        </a>

        {test.location && (
          <div className="flex items-center gap-0.5 charcoal-300">
            <span className="small">{test.location}</span>
            <CopyButton contentToCopy={test.location} iconOnly={true} />
          </div>
        )}
      </div>
      <div className="right-align">
        {test.reliability}
        <span className="hide"> reliable</span>
      </div>
      <div className="right-align">
        {test.average_duration}
        <span className="hide"> average duration</span>
      </div>
    </div>
  </li>
);

export default Row;
