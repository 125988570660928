/* eslint-disable id-length */

import { ChartOptions } from "chart.js";
import { merge } from "lodash";

const commonOptions: ChartOptions = {
  responsive: true,
  animation: false,
  maintainAspectRatio: false,
  clip: 8,
  layout: { padding: 8 },
  plugins: {
    legend: {
      display: false,
    },
  },
  datasets: {
    line: {
      borderWidth: 1.75,
      normalized: false,
      pointRadius: 0,
      spanGaps: true,
      tension: 0.25,
    },
  },
  scales: {
    x: {
      grid: { display: false },
    },
    y: {
      grid: { display: false },
    },
  },
};

export function chartOptions(
  options: ChartOptions,
  overrideOptions?: ChartOptions,
) {
  return merge({}, commonOptions, options, overrideOptions);
}
