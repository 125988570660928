import { ChartDataset } from "chart.js";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./Table";
import { ForwardedChartHandle } from "../shared/Chart/chart";
import { useHighlightDataset, useToggleDatasets } from "./Chart/hooks";
import classNames from "classnames";
import { LegendIndicator } from "./LegendIndicator";

export function ChartLegend<Dataset extends ChartDataset<"line", unknown>>({
  labelHeader = "Metric",
  chartRef,
  datasets,
  columns = {},
}: {
  labelHeader?: string;
  chartRef: React.RefObject<ForwardedChartHandle>;
  datasets: Dataset[];
  columns?: Record<
    string,
    (dataset: Dataset, index: number, visible: boolean) => React.ReactNode
  >;
}) {
  const { isDatasetVisible, visibilityHandlers } = useToggleDatasets(chartRef);
  const highlightHandlers = useHighlightDataset(chartRef);

  return (
    <Table className="lg:basis-96">
      <TableHead>
        <TableRow>
          <TableHeader>{labelHeader}</TableHeader>
          {Object.keys(columns).map((column) => (
            <TableHeader key={column}>{column}</TableHeader>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {datasets.map((dataset, index) => {
          const { label, borderColor } = dataset;
          const visible = isDatasetVisible(index);

          return (
            <TableRow
              key={label}
              className={classNames({ "text-slate-500": !visible })}
              {...visibilityHandlers(index)}
              {...highlightHandlers(index)}
            >
              <TableCell
                className="font-semibold whitespace-normal"
                title={label}
              >
                <div className="line-clamp-1">
                  <LegendIndicator
                    color={borderColor as string}
                    className="mr-1.5 ml-1"
                    selected={visible}
                  />
                  {label}
                </div>
              </TableCell>

              {Object.keys(columns).map((column) => (
                <TableCell key={column}>
                  {columns[column](dataset, index, visible)}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
