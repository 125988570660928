import { CommandJob } from "app/components/build/Header/pipeline/types/CommandJob";
import Emojify from "app/components/shared/Emojify";
import { Job } from "app/stores/BuildShowStore";
import { SortFn } from ".";

const JobCount = ({ job }: { job: CommandJob }) => {
  let count: string | null = null;
  if (job.parallelGroupIndex !== null && job.parallelGroupIndex !== undefined) {
    count = `${job.parallelGroupIndex + 1}/${job.parallelGroupTotal}`;
  }

  if (!count) {
    return null;
  }

  return (
    <span className="rounded-[3px] px-0.5 border border-current text-xs bg-white inline-block">
      {count}
    </span>
  );
};

export function Label({ job }: { job: Job }) {
  const label = job.name || "";

  return (
    <div className="flex gap-1 items-center">
      {job.type === "script" && <JobCount job={job} />}

      <Emojify
        text={label}
        className="font-medium truncate flex items-center gap-1 [&>img]:shrink-0"
      />
    </div>
  );
}

/* eslint-disable id-length */
export const sortByLabel: SortFn = (direction) => (a, b) => {
  const aLabel = a.name || "";
  const bLabel = b.name || "";

  if (direction === "asc") {
    return bLabel?.localeCompare(aLabel);
  }
  return aLabel.localeCompare(bLabel);
};
