import Icon from "app/components/shared/Icon";

export type Tag = {
  value: string;
  removePath: string;
};

type Props = {
  tags: Tag[] | undefined;
  groupByTag: Tag[] | undefined;
};

const TagFilters = ({ tags, groupByTag }: Props) => {
  const tagsEnabled = window.Features.AnalyticsShowExecutionTags;
  const showTags = tags && tags.length > 0;
  const showGroupBy = groupByTag && groupByTag.length > 0;

  if (!tagsEnabled || (!showTags && !showGroupBy)) {
    return null;
  }

  return (
    <div className="flex flex-col gap2 pl-1">
      {showGroupBy && (
        <div className="flex flex-wrap items-center gap1">
          <h3 className="text-sm text-gray-800 m-0">Grouped by</h3>
          {groupByTag.map((group) => (
            <a
              key={group.value}
              href={group.removePath}
              className="badge badge--outline truncate flex pr-0"
            >
              {group.value}
              <span className="border-l border-gray-300 ml-1 flex items-center">
                <Icon
                  icon="close"
                  className="flex-none"
                  style={{ height: 18, width: 18 }}
                />
              </span>
            </a>
          ))}
        </div>
      )}

      {showTags && (
        <div className="flex flex-wrap items-center gap1">
          <h3 className="text-sm text-gray-800 m-0">Filtered by</h3>
          {tags.map((tag) => (
            <a
              key={tag.value}
              href={tag.removePath}
              className="badge badge--outline truncate flex pr-0"
            >
              {tag.value}
              <span className="border-l border-gray-300 ml-1 flex items-center">
                <Icon
                  icon="close"
                  className="flex-none"
                  style={{ height: 18, width: 18 }}
                />
              </span>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default TagFilters;
