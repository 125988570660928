import * as React from "react";
import {
  RelayProp,
  QueryRenderer,
  createFragmentContainer,
  graphql,
  commitMutation,
} from "react-relay";
import { buildClientSchema, GraphQLSchema, IntrospectionQuery } from "graphql";

import Environment from "app/lib/relay/environment";
import SectionLoader from "app/components/shared/SectionLoader";
import Button from "app/components/shared/Button";
import Dropdown from "app/components/shared/Dropdown";
import Notice from "app/components/shared/Notice";
import FlashesStore from "app/stores/FlashesStore";
import Editor from "./Editor";
import ResultsViewer from "./ResultsViewer";
import { executeQuery, prettifyQuery } from "./query";
import consoleState from "../state";
import GraphqlExplorerLayout from "../Layout";

type Organization = {
  id: string;
  name: string;
  path: string;
};

type Props = {
  relay: RelayProp;
  schema: IntrospectionQuery;
  viewer: any;
  graphQLSnippet?: any;
  orgsWithNoActiveSession: Array<Organization>;
};

type State = {
  results?:
    | {
        output: string;
        performance: string;
      }
    | null
    | undefined;
  query?: string;
  schema?: GraphQLSchema;
  currentOperationName?: string | null | undefined;
  allOperationNames?: Array<string> | null | undefined;
  executing: boolean;
  sharing: boolean;
  shareLink: string | null | undefined;
};

class GraphQLExplorerConsole extends React.PureComponent<Props, State> {
  operationsDropdownComponent: Dropdown | null | undefined;
  shareDropdownComponent: Dropdown | null | undefined;
  shareLinkTextInput: HTMLInputElement | null | undefined;
  focusOnSelectShareLinkOnNextUpdate: boolean | null | undefined;

  state: State = {
    results: null,
    query: "",
    currentOperationName: "",
    allOperationNames: null,
    executing: false,
    sharing: false,
    shareLink: null,
  };

  constructor(props: any) {
    super(props);

    if (
      this.props.viewer.organizations &&
      this.props.viewer.organizations.edges
    ) {
      consoleState.setOrganizationEdges(this.props.viewer.organizations.edges);
    }

    if (this.props.graphQLSnippet) {
      consoleState.setGraphQLSnippet(this.props.graphQLSnippet);
    }

    const defaultState = consoleState.toStateObject();
    this.state = {
      results: null,
      schema: buildClientSchema(this.props?.schema),
      query: defaultState.query,
      currentOperationName: defaultState.currentOperationName,
      allOperationNames: defaultState.allOperationNames,
      executing: false,
      sharing: false,
      shareLink: this.props.graphQLSnippet
        ? this.props.graphQLSnippet.url
        : null,
    };
  }

  executeCurrentQuery() {
    this.setState({ executing: true });

    const payload = {
      query: this.state.query,
      operationName: this.state.currentOperationName,
    } as const;

    executeQuery(payload).then((response) => {
      response.json().then(
        (json) => {
          // Once we've got the response back, and converted it to JSON, let's
          // turn it back into a string... The things we do to make it look
          // pretty! (The 2 means indent each nested object with 2 spaces)
          const prettyJSONString = JSON.stringify(json, null, 2);

          // Get performance information out of the query if there is any.
          const responsePerformanceInformation = response.headers.get(
            "x-buildkite-metrics",
          );

          // Also store the pretty JSON in the results cache so next time we
          // re-render the component we can show the previous results.
          // Makes for great tab switching!
          this.setState(
            consoleState.setResults(
              prettyJSONString,
              responsePerformanceInformation,
            ),
          );

          // Tell the console we're not executing anymore, and that it can stop
          // showing a spinner.
          this.setState({ executing: false });
        },
        () => {
          // If there was an error parsing the results, we generate an error message.
          const prettyJSONString = JSON.stringify(
            {
              error: [
                "There was an error parsing the response.",
                `Graph API returned a ${response.status} response status.`,
              ],
            },
            null,
            2,
          );

          this.setState(consoleState.setResults(prettyJSONString, ""));

          // Tell the console we're not executing anymore, and that it can stop
          // showing a spinner.
          this.setState({ executing: false });
        },
      );
    });
  }

  invalidateShareLink() {
    if (this.state.shareLink) {
      this.setState({ shareLink: null });
      window.history.pushState(this.state, "", "/user/graphql/console");
    }
  }

  componentDidUpdate() {
    if (this.shareLinkTextInput && this.focusOnSelectShareLinkOnNextUpdate) {
      this.shareLinkTextInput.select();
      this.focusOnSelectShareLinkOnNextUpdate = null;
    }
  }

  render() {
    return (
      <div data-testid="GraphQLExplorerConsole">
        {this.props.orgsWithNoActiveSession.length ? (
          <div className="mb-4">
            <Notice noticeType="warning">
              <p className="my-0">
                You do not have active sessions with the following
                organizations:
              </p>
              <ul className="list-disc list-inside pl-5">
                {this.props.orgsWithNoActiveSession.map((org) => (
                  <li key={org.id}>
                    <a href={org.path} className="black hover-underline">
                      {org.name}
                    </a>
                  </li>
                ))}
              </ul>
              <p className="my-0">
                Queries against those organizations will return empty results
                until you re-authenticate with those organizations.
              </p>
            </Notice>
          </div>
        ) : null}
        <div className="mb3 flex justify-start">
          <div className="flex items-center">
            <Button
              onClick={this.handleExecuteClick}
              // @ts-expect-error - TS2322 - Type 'false | "Executing…"' is not assignable to type 'boolean | undefined'.
              loading={this.state.executing && "Executing…"}
              theme="primary"
            >
              Execute
            </Button>
            {this.renderOperationsDropdown()}
          </div>

          <div className="flex flex-auto justify-end items-center pl2">
            {this.renderShareLink()}

            <Dropdown
              width={320}
              ref={(component) => (this.shareDropdownComponent = component)}
            >
              {/* @ts-expect-error - TS2322 - Type 'false | "Generating share link…"' is not assignable to type 'boolean | undefined'. */}
              <Button loading={this.state.sharing && "Generating share link…"}>
                Share Query
              </Button>

              <div className="mx3 my2">
                <p className="mt2">
                  When you share a GraphQL query, Buildkite generates a unique
                  URL for it. Anyone with that URL and a Buildkite account will
                  be able to see your query and arguments.
                </p>
                <p>
                  If they run your shared query, the result will only include
                  items they already have access to, according to their
                  Organization and Team memberships.
                </p>
                <Button
                  onClick={this.handleShareClick}
                  style={{ width: "100%" }}
                  theme="primary"
                >
                  Ok, Share this Query
                </Button>
              </div>
            </Dropdown>

            <Button className="ml2" onClick={this.handlePrettifyClick}>
              Prettify
            </Button>
          </div>
        </div>

        <div
          className="flex flex-fit border border-gray rounded"
          style={{ width: "100%" }}
        >
          <div className="col-6" style={{ minHeight: 500 }}>
            <Editor
              value={this.state.query}
              schema={this.state.schema}
              onChange={this.handleEditorChange}
              onExecuteQueryPress={this.handleEditorExecutePress}
            />
          </div>

          <div className="col-6 border-left border-gray">
            <div
              className="relative flex flex-wrap items-stretch"
              style={{ height: "100%", width: "100%" }}
            >
              {this.renderOutputPanel()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOperationsDropdown() {
    if (
      !this.state.allOperationNames ||
      (this.state.allOperationNames && !this.state.allOperationNames.length)
    ) {
      return;
    }

    return (
      <div className="ml2">
        <Dropdown
          width={250}
          ref={(component) => (this.operationsDropdownComponent = component)}
        >
          <div className="underline-dotted cursor-pointer inline-block">
            {this.state.currentOperationName}
          </div>
          {this.state.allOperationNames.map((operation) => {
            return (
              <div
                key={operation}
                className="btn block hover-bg-silver"
                onClick={(event) =>
                  this.handleOperationSelect(event, operation)
                }
              >
                <span
                  className="block monospace truncate"
                  style={{ fontSize: 12 }}
                >
                  {operation}
                </span>
              </div>
            );
          })}
        </Dropdown>
      </div>
    );
  }

  renderOutputPanel() {
    if (this.state.results) {
      return (
        <>
          <ResultsViewer
            results={this.state.results.output}
            className="p1 flex-auto bg-silver"
            style={{ width: "100%" }}
          />
          {this.renderDebuggingInformation()}
        </>
      );
    }

    return (
      <div
        className="flex items-center justify-center absolute bg-silver"
        style={{ top: 0, left: 0, right: 0, bottom: 0, zIndex: 2 }}
      >
        <span>
          Hit the <span className="semi-bold">Execute</span> above button to run
          this query! ☝️{" "}
        </span>
      </div>
    );
  }

  renderDebuggingInformation() {
    // Only render debugging information if we've got some to show, and we're
    // in "debug" mode.
    const queryParam = new URLSearchParams(window.location.search);
    if (
      this.state.results &&
      this.state.results.performance &&
      queryParam.get("debug") === "true"
    ) {
      return (
        <div className="px3 py2 border-top border-gray bg-silver col-12 flex-none">
          <div className="bold black mb1">Performance 🚀</div>
          <pre className="monospace" style={{ fontSize: 12 }}>
            {this.state.results.performance.split("; ").join("\n")}
          </pre>
        </div>
      );
    }
  }

  renderShareLink() {
    if (this.state.shareLink) {
      return (
        <div className="flex-auto mr2" style={{ maxWidth: 450 }}>
          <input
            ref={(textInput) => (this.shareLinkTextInput = textInput)}
            type="text"
            readOnly={true}
            value={this.state.shareLink}
            style={{ width: "100%", fontSize: "inherit" }}
            className="p2 m0 rounded border border-gray bg-silver"
            onClick={this.handleShareLinkClick}
          />
        </div>
      );
    }
  }

  handleOperationSelect = (event: any, operationName: string) => {
    event.preventDefault();

    if (this.operationsDropdownComponent) {
      this.operationsDropdownComponent.setShowing(false);
    }

    this.setState(consoleState.setCurrentOperationName(operationName));
  };

  handleEditorChange = (query: any) => {
    this.setState(consoleState.setQuery(query));

    this.invalidateShareLink();
  };

  handleExecuteClick = (event: any) => {
    event.preventDefault();

    this.executeCurrentQuery();
  };

  handleEditorExecutePress = () => {
    this.executeCurrentQuery();
  };

  handlePrettifyClick = async () => {
    const query = await prettifyQuery(this.state.query);
    this.setState({ query: query });
  };

  handleShareLinkClick = () => {
    if (this.shareLinkTextInput) {
      this.shareLinkTextInput.select();
    }
  };

  handleShareClick = () => {
    this.invalidateShareLink();
    this.setState({ sharing: true });

    if (this.shareDropdownComponent) {
      this.shareDropdownComponent.setShowing(false);
    }

    const mutation = graphql`
      mutation Console_graphQLSnippetCreateMutation(
        $input: GraphQLSnippetCreateInput!
      ) {
        graphQLSnippetCreate(input: $input) {
          graphQLSnippet {
            url
          }
        }
      }
    `;

    const variables = {
      input: {
        query: this.state.query,
        operationName: this.state.currentOperationName,
      },
    } as const;

    commitMutation<any>(this.props.relay.environment, {
      mutation: mutation,
      variables: variables,
      onCompleted: this.handleMutationComplete,
      onError: this.handleMutationError,
    });
  };

  handleMutationError = (error: any) => {
    if (error) {
      // @ts-expect-error - TS2339 - Property 'ERROR' does not exist on type 'FlashesStore'.
      FlashesStore.flash(FlashesStore.ERROR, error);
    }

    this.setState({ sharing: false });
  };

  handleMutationComplete = (response: any) => {
    const shareLinkURL = new URL(
      response.graphQLSnippetCreate.graphQLSnippet.url,
    );

    this.focusOnSelectShareLinkOnNextUpdate = true;
    this.setState({ sharing: false, shareLink: shareLinkURL.href });
  };
}

const GraphQLExplorerConsoleContainer = createFragmentContainer(
  GraphQLExplorerConsole,
  {
    graphQLSnippet: graphql`
      fragment Console_graphQLSnippet on GraphQLSnippet {
        query
        operationName
        url
      }
    `,
    viewer: graphql`
      fragment Console_viewer on Viewer {
        organizations(first: 100) {
          edges {
            node {
              id
              name
              slug
              permissions {
                pipelineView {
                  allowed
                  code
                }
              }
            }
          }
        }
      }
    `,
  },
);

const GraphQLExplorerConsoleContainerQuery = graphql`
  query ConsoleSnippetQuery($hasSnippet: Boolean!, $snippet: String!) {
    viewer {
      ...Console_viewer
    }
    graphQLSnippet(uuid: $snippet) @include(if: $hasSnippet) {
      query
      operationName
      url
      ...Console_graphQLSnippet
    }
  }
`;

type ContainerProps = {
  snippet?: string;
  schema?: any;
  orgsWithNoActiveSession: Array<Organization>;
};

/* eslint-disable react/no-multi-comp */
export default class GraphQLExplorerConsoleQueryContainer extends React.PureComponent<ContainerProps> {
  environment = Environment.get();
  variables = {};

  get snippet(): string {
    return this.props.snippet || "";
  }

  get hasSnippet(): boolean {
    return this.snippet !== "";
  }

  render() {
    return (
      <GraphqlExplorerLayout>
        <QueryRenderer
          environment={this.environment}
          query={GraphQLExplorerConsoleContainerQuery}
          variables={{
            snippet: this.snippet,
            hasSnippet: this.hasSnippet,
            schema: this.props.schema,
            orgsWithNoActiveSession: this.props.orgsWithNoActiveSession,
          }}
          render={this.renderQuery}
        />
      </GraphqlExplorerLayout>
    );
  }

  renderQuery({ props }: { props: any }) {
    if (props) {
      return (
        <GraphQLExplorerConsoleContainer
          // `graphQLSnippet` here needs to be an explicit null to avoid a
          // React warning because of the weird `@include` stuff we’re doing.
          // $FlowExpectError
          graphQLSnippet={null}
          {...props}
          {...this.variables}
        />
      );
    }
    return <SectionLoader />;
  }
}
