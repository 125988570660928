export default interface Theme {
  color: string;
  fillColors: string[];
}

export const ThemeDefaultTrend: Theme = {
  color: "#2664EC",
  fillColors: ["#DCE6FC", "white"],
};

export const ThemeNegativeTrend: Theme = {
  color: "#FF1744",
  fillColors: ["#FFDAE1", "white"],
};

export const ChartColors = [
  { borderColor: "#8e64e0", backgroundColor: "#e8e0f9" },
  { borderColor: "#d473b5", backgroundColor: "#f6e3f0" },
  { borderColor: "#ff7b54", backgroundColor: "#ffe5dd" },
  { borderColor: "#ffb037", backgroundColor: "#ffefd7" },
  { borderColor: "#2bb4a9", backgroundColor: "#d5f0ee" },
  { borderColor: "#3ca3e9", backgroundColor: "#d8edfb" },
  { borderColor: "#4b7af4", backgroundColor: "#dbe4fd" },
  { borderColor: "#4b19d5", backgroundColor: "#dbd1f7" },
];

export function colorsForIndex(index: number) {
  return ChartColors[index % ChartColors.length];
}
