import { useEffect, useRef } from "react";
import useFetch from "use-http";
import ChartJS, { type ChartConfiguration } from "chart.js/auto";

import Spinner from "app/components/shared/Spinner";
import { DateFilter } from "app/components/analytics/shared/DateFilters";
import { LoadingChart } from "app/components/analytics/shared/LoadingStates";
import { Metrics } from "app/components/analytics/shared/type";
import { chartOptions } from "app/components/analytics/shared/chartHelpers";

type ChartMetrics = Pick<Metrics, "chartType" | "metric">;

type Props = ChartMetrics & {
  height: string | number;
  width?: string | number;
  label?: string | null | undefined;
  options?: object | null | undefined;
  chartDataUrl: string;
  period: DateFilter;
};

type ChartType = { destroy: () => void };

// Chart is a wrapper around Chart.js that fetches data from a given URL and renders a chart.
const Chart = ({
  height,
  width,
  label,
  options,
  chartDataUrl,
  metric,
  period,
  chartType,
}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  let chart: ChartType;

  ChartJS.defaults.font.family =
    "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, sans-serif";

  const url = new URL(chartDataUrl);
  url.searchParams.set("metric", metric);
  // We still need to support the period filter changing by react state,
  // this is used in the InteractiveChart dialog and in the suite summary
  url.searchParams.set("period", period);

  const { data, loading, error } = useFetch(url.href, [period, metric]);

  useEffect(() => {
    if (data && canvasRef.current) {
      const setChartOptions = chartOptions(
        chartType,
        data,
        metric,
        label,
        options,
      ) as ChartConfiguration;

      chart = new ChartJS(canvasRef.current, setChartOptions);
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [data]);

  if (error) {
    return <div>There was an error loading the chart</div>;
  }

  return (
    <div data-testid="chart-container" style={{ height: height }}>
      {/* use the LoadingChart for MiniChart and Spinner otherwise */}
      {loading && height === 50 && <LoadingChart barWidth="25px" />}
      {loading && height !== 50 && <Spinner />}
      {!loading && data && (
        <canvas
          height={height}
          width={width}
          ref={canvasRef}
          data-testid="canvas"
        />
      )}
    </div>
  );
};

export default Chart;
