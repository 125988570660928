import { Chart, DoughnutController } from "chart.js";

type DoughnutChartBackgroundPluginOptions = {
  enabled: boolean;
  color: string;
};

function handler(
  chart: Chart<"doughnut">,
  args,
  options: DoughnutChartBackgroundPluginOptions,
) {
  const { ctx, width, height } = chart;

  const { innerRadius }: DoughnutController = chart.getDatasetMeta(
    chart.data.datasets.length - 1,
  ).controller as DoughnutController;

  const { outerRadius } = chart.getDatasetMeta(0)
    .controller as DoughnutController;
  const radiusLength = outerRadius - innerRadius;

  if (options.enabled) {
    ctx.beginPath();
    ctx.arc(
      width / 2,
      height / 2,
      outerRadius - radiusLength / 2,
      0,
      2 * Math.PI,
    );
    ctx.lineWidth = radiusLength;
    ctx.strokeStyle = options.color;
    ctx.stroke();
  }
}

export default {
  id: "doughnutChartBackground",
  beforeDatasetsDraw: handler,
};
