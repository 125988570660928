/* eslint-disable react/jsx-no-bind */
import { DrawerOutlet } from "../components/DrawerOutlet";
import { useBuild } from "app/components/Playground/BuildContext";
import { ErrorBoundary } from "app/lib/Bugsnag";
import { ErrorView } from "app/components/shared/ErrorView";
import { useNavigate } from "react-router-dom";
import { TableView } from "../components/TableView";

export default function TablePage() {
  const { build, store } = useBuild();
  const navigate = useNavigate();

  if (!build || !store) {
    throw new Error("Missing build context");
  }

  return (
    <>
      <ErrorBoundary
        FallbackComponent={() => (
          <ErrorView className="px-4 py-2" clearError={() => navigate(0)} />
        )}
      >
        <div className="flex-auto relative">
          <div className="flex w-full h-full absolute overflow-y-auto">
            <TableView />
          </div>
        </div>
      </ErrorBoundary>

      <DrawerOutlet />
    </>
  );
}
