import { useState } from "react";
import styled from "styled-components";
import Chart from "../Chart";
import DateFilters, {
  DateFilter,
} from "app/components/analytics/shared/DateFilters";
import Colors from "app/constants/analytics/Colors";
import Emojify from "app/components/shared/Emojify";
import set from "lodash/set";
import { getMicrosecondDurationFormattedString } from "app/lib/date";
import { Metrics } from "app/components/analytics/shared/type";

export type InteractiveChartProps = Metrics & {
  heading?: string;
  chartDataUrl: string;
  period: DateFilter;
};

const TIMED_METRICS = [
  "p50_duration",
  "p95_duration",
  "duration",
  "total_duration",
  "average_execution_duration",
  "speed",
];

const UTCNote = styled.div`
  color: ${Colors.BASE_GRAY};
  margin-top: 30px;
`;

const formatReliability = (reliability) => {
  if (reliability === undefined) {
    return;
  }

  return `${Number.parseFloat(reliability).toFixed(2)}%`;
};

export const chartOptions = (props) => {
  const options = {};

  const formatTooltip = (value, format) => {
    const label = value.dataset?.label ? `${value.dataset.label}: ` : "";
    return value.parsed.y == null
      ? `${label}No data available for this day`
      : `${label}${format}`;
  };

  if (TIMED_METRICS.includes(props.metric)) {
    set(options, "options.scales.y.ticks", {
      callback: (value) =>
        getMicrosecondDurationFormattedString(value * 1_000_000),
    });
    set(options, "options.plugins.tooltip.callbacks", {
      label: (value) =>
        formatTooltip(
          value,
          getMicrosecondDurationFormattedString(value.parsed.y * 1_000_000),
        ),
    });
  } else if (props.metric === "reliability") {
    set(options, "options.scales.y.ticks", {
      callback: (value) => `${value}%`,
    });
    set(options, "options.plugins.tooltip.callbacks", {
      label: (value) => formatTooltip(value, formatReliability(value.parsed.y)),
    });
  } else {
    set(options, "options.plugins.tooltip.callbacks", {
      label: (value) => formatTooltip(value, value.parsed.y),
    });
  }

  return options;
};

// InteractiveChart renders an interactive chart with a date filter and header.
// `chartDataUrl` is passed through into the Chart component, which then fetches the data for the chart.
// Changing the date filter will cause the chart to update the `period` state and re-fetch the data.
const InteractiveChart = (props: InteractiveChartProps) => {
  const [chartPeriod, setChartPeriod] = useState(props.period);

  return (
    <>
      {props.heading && (
        <h2 className="text-lg">
          <Emojify text={props.heading} />
        </h2>
      )}
      <DateFilters
        className="mb4"
        subHeading={props.label}
        filter={chartPeriod}
        setFilter={setChartPeriod}
        tooltip={
          props.description
            ? {
                name: `${props.label} calculation`,
                description: props.description,
              }
            : undefined
        }
      />
      <Chart
        height="25vh"
        period={chartPeriod}
        label={props.label}
        metric={props.metric}
        chartDataUrl={props.chartDataUrl}
        options={chartOptions(props)}
        chartType={props.chartType}
      />
      <UTCNote>Note: Dates in UTC</UTCNote>
    </>
  );
};

export default InteractiveChart;
