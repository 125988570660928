import { twMerge } from "tailwind-merge";

export function Table({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLTableElement>) {
  return (
    <div
      className={twMerge(
        "border border-slate-300 overflow-hidden rounded-md text-charcoal-600 overflow-y-scroll table-fixed",
        className,
      )}
      {...props}
    >
      <table className="w-full">{children}</table>
    </div>
  );
}

export function TableHead({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLTableSectionElement>) {
  return (
    <thead className={twMerge("text-left", className)} {...props}>
      {children}
    </thead>
  );
}

export function TableBody({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLTableSectionElement>) {
  return (
    <tbody
      className={twMerge("[&_tr:hover]:bg-slate-100", className)}
      {...props}
    >
      {children}
    </tbody>
  );
}

export function TableRow({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLTableRowElement>) {
  return (
    <tr className={twMerge("cursor-pointer", className)} {...props}>
      {children}
    </tr>
  );
}

export function TableHeader({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLTableCellElement>) {
  return (
    <th
      className={twMerge(
        "px-1.5 first-of-type:pl-3 last-of-type:pr-3 py-2 font-medium uppercase text-[11px] tracking-wide text-charcoal-400 sticky top-0 z-1 bg-slate-100 border-b whitespace-nowrap",
        className,
      )}
      {...props}
    >
      {children}
    </th>
  );
}

export function TableCell({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLTableCellElement>) {
  return (
    <td
      className={twMerge(
        "font-medium px-1.5 first-of-type:pl-3 last-of-type:pr-3 py-2 text-xs align-baseline whitespace-nowrap",
        className,
      )}
      {...props}
    >
      {children}
    </td>
  );
}

export function TruncatedTableCell({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLTableCellElement>) {
  return (
    <TableCell className={twMerge("whitespace-normal", className)} {...props}>
      <div className="line-clamp-1">{children}</div>
    </TableCell>
  );
}
