import { Series } from "./types";
import ComputeChart from "app/components/shared/ComputeChart";
import { ChartDataset, ChartOptions } from "chart.js";
import { convertToChartDatasets } from "./common";

interface MemoryComputeChartProps {
  chartData: Series;
  height?: number;
}

export const MemoryComputeChart = (props: MemoryComputeChartProps) => {
  const datasets = convertToChartDatasets(props.chartData).map((dataset) => {
    dataset.data = (dataset.data as number[]).map((value) => value / 1000000);

    switch (dataset.label) {
      case "mem_available":
        return {
          ...dataset,
          label: "Available Memory",
          order: 1,
        };
      case "mem_used":
        return {
          ...dataset,
          label: "Used Memory",
          order: 0,
          fill: true,
        };
    }
  });

  return (
    <ComputeChart
      height={props.height}
      chartType="line"
      chartOptions={memoryChartOptions}
      chartData={{
        labels: props.chartData.timestamps,
        datasets: datasets as ChartDataset[],
      }}
    />
  );
};

const memoryChartOptions: ChartOptions = {
  scales: {
    // eslint-disable-next-line id-length
    y: {
      stacked: true,
      title: {
        text: "Megabytes",
      },
    },
  },
};
