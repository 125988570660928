import Duration from "app/components/shared/Duration";
import { getDuration } from "app/lib/date";
import { jobTime } from "app/lib/jobs";
import { Job } from "app/stores/BuildShowStore";
import { SortFn } from ".";
import Icon from "app/components/shared/Icon";

const getJobDuration = (job: Job) => {
  const { from, to } = jobTime({ ...job, state: job.state.toUpperCase() });
  return getDuration(from, to).asSeconds();
};

export function RunDuration({ job }: { job: Job }) {
  if (job.type !== "script" && job.type !== "trigger") {
    return null;
  }

  if (!job.startedAt) {
    return null;
  }

  const timestamps = jobTime({ ...job, state: job.state.toUpperCase() });

  return (
    <div className="flex items-center gap-1">
      <Icon icon="heroicons/outline/clock" className="w-4 h-4 block" />
      <Duration.Short {...timestamps} />
    </div>
  );
}

// eslint-disable-next-line id-length
export const sortByRunDuration: SortFn = (direction) => (a, b) => {
  const aDuration = getJobDuration(a);
  const bDuration = getJobDuration(b);

  if (aDuration === bDuration) {
    return 0;
  }

  if (direction === "asc") {
    return aDuration > bDuration ? 1 : -1;
  }

  return aDuration > bDuration ? -1 : 1;
};
