import { useCallback } from "react";

import Icon from "app/components/shared/Icon";
import useCopyToClipboard from "../analytics/shared/hooks/useCopyToClipboard";

type Props = {
  contentToCopy: string;
  text?: string;
  iconOnly?: boolean;
};

const CopyButton = ({ contentToCopy, text, iconOnly }: Props) => {
  const { copied, handleCopy } = useCopyToClipboard();

  const handleCopyClick = useCallback(
    (event) => {
      event.currentTarget.blur();
      handleCopy(contentToCopy);
    },
    [contentToCopy, handleCopy],
  );

  if (!navigator.clipboard || !window.isSecureContext) {
    return null;
  }

  const notice = copied ? "Copied!" : "Copy";

  return (
    <button
      aria-label={iconOnly ? notice : undefined}
      className="hover:bg-gray-200 px-1 py-0.5 rounded"
      onClick={handleCopyClick}
    >
      <span>
        {copied ? (
          <Icon
            icon="permission-small-tick"
            style={{ width: 16 }}
            className="mb-0.5 text-green-500"
          />
        ) : (
          <Icon icon="copy" style={{ width: 16 }} className="mb-0.5" />
        )}
      </span>

      {!iconOnly && <span className="ml-1">{copied ? "Copied!" : text}</span>}
    </button>
  );
};

export default CopyButton;
