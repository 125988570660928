import { useState } from "react";
import styled from "styled-components";

import DateFilters, {
  DateFilter,
} from "app/components/analytics/shared/DateFilters";
import { Metrics } from "app/components/analytics/shared/type";
import ChartCard from "./ChartCard";
import TagFilters, { Tag } from "./TagFilters";

const CardContainer = styled.div`
  gap: 15px;
  padding-bottom: 20px;
  justify-content: center;

  @media (max-width: 768px) {
    flex: 1 1 auto;
    flex-direction: column;
  }

  > div {
    @media (max-width: 1320px) {
      flex: 1 0 25%;
    }
  }
`;

type Props = {
  heading: string;
  chartDataUrl: string;
  chartMetricUrl: string;
  modal_heading: string;
  metrics: Array<Metrics>;
  hideDateFilter?: boolean;
  period?: DateFilter | null;
  tags?: Tag[];
  groupByTag?: Tag[];
};

// MetricChartCards is used to render a set of metric as cards,
// each with a non-interactive mini chart and a popover interactive chart.
// This component also handles react-based date filtering and displaying tag filters.
const MetricChartCards = ({
  heading,
  metrics,
  chartDataUrl,
  chartMetricUrl,
  modal_heading,
  hideDateFilter,
  period,
  tags,
  groupByTag,
}: Props) => {
  // If period is set in the URL, we pass it through from the view and
  // use that as the default
  const [metricCardPeriod, setMetricCardPeriod] = useState<DateFilter>(
    period || "28days",
  );

  return (
    <div className="flex flex-column flex-auto">
      <DateFilters
        heading={heading}
        filter={metricCardPeriod}
        setFilter={setMetricCardPeriod}
        hideDateFilter={hideDateFilter}
      />

      <TagFilters tags={tags} groupByTag={groupByTag} />

      <CardContainer className="flex mt3">
        {metrics.map(({ label, metric, description, chartType }) => (
          <ChartCard
            key={`${metric}-${label}`}
            metric={metric}
            label={label}
            period={metricCardPeriod}
            chartDataUrl={chartDataUrl}
            chartMetricUrl={chartMetricUrl}
            heading={modal_heading}
            description={description}
            chartType={chartType}
          />
        ))}
      </CardContainer>
    </div>
  );
};

export default MetricChartCards;
