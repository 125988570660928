import FriendlyTime from "app/components/shared/FriendlyTime";
import React from "react";

export interface TestResolvedEvent {
  uuid: string;
  type: "test_resolved";
  timestamp: Date;
  data: {
    actor_system?: string;
    actor_user?: {
      name: string;
    };
    new_state: string;
  };
}

interface Props {
  event: TestResolvedEvent;
}

const TestResolved = ({ event }: Props) => {
  const { actor_user, actor_system } = event.data;
  return (
    <div className="flex">
      <div className="grow">
        Test marked as resolved
        {actor_user && ` by ${actor_user.name}`}
        {actor_system && ` via ${actor_system}`}.
      </div>
      <div className="justify-self-end">
        <FriendlyTime className="dark-gray" value={event.timestamp} />
      </div>
    </div>
  );
};

export default TestResolved;
