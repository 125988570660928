import ComputeChart from "app/components/shared/ComputeChart";
import { ChartOptions } from "chart.js";
import { Series } from "app/components/job/Job/HostedCompute/types";
import { convertToChartDatasets } from "app/components/job/Job/HostedCompute/common";

interface PercentageComputeChartProps {
  chartData: Series;
  height?: number;
}

export const PercentageComputeChart = (props: PercentageComputeChartProps) => {
  return (
    <ComputeChart
      height={props.height}
      chartType="line"
      chartOptions={percentageChartOptions}
      chartData={{
        labels: props.chartData.timestamps,
        datasets: convertToChartDatasets(props.chartData),
      }}
    />
  );
};

const percentageChartOptions: ChartOptions<"line"> = {
  scales: {
    // eslint-disable-next-line id-length
    y: {
      max: 100,
      title: {
        text: "Percentage",
      },
    },
  },
};
