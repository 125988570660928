/* eslint-disable id-length */
import {
  ChartData,
  ChartOptions,
  TooltipModel,
  DefaultDataPoint,
} from "chart.js";
import { forwardRef, Ref } from "react";

import { chartOptions } from "./chartOptions";
import { formatDuration } from "./Chart/utils";
import { createTooltipElement, verticalHoverLine } from "./Chart/plugins";
import {
  ForwardedChart,
  ForwardedChartHandle,
} from "app/components/shared/Chart/chart";

export enum LineChartUnit {
  number = "number",
  percentage = "percentage",
  time = "time",
  duration = "duration",
}

function LineChart<DataPoint = DefaultDataPoint<"line">>(
  {
    data,
    options = {},
    tooltip,
    units,
  }: {
    data: ChartData<"line", DataPoint[]>;
    options?: ChartOptions;
    units?: {
      x?: LineChartUnit;
      y?: LineChartUnit;
    };
    tooltip?: React.FC<{ tooltip: TooltipModel<"line"> }>;
  },
  ref: Ref<ForwardedChartHandle>,
) {
  return (
    <div className="relative flex h-full">
      <ForwardedChart
        ref={ref}
        chartOptions={{
          type: "line",
          data,
          plugins: [verticalHoverLine],
          options: chartOptions(
            {
              plugins: tooltip && {
                tooltip: {
                  enabled: false,
                  mode: "index",
                  external: createTooltipElement(tooltip),
                },
              },
              interaction: {
                mode: "index",
                intersect: false,
              },
              elements: {
                point: {
                  drawActiveElementsOnTop: true,
                  radius: 0,
                  hoverRadius: 2,
                  borderWidth: 0.5,
                },
              },
              scales: {
                x: {
                  type: "time",
                  time: {
                    displayFormats: {
                      hour: "HH:mm",
                      minute: "HH:mm",
                    },
                  },
                  ticks: {
                    maxRotation: 0,
                    autoSkipPadding: 16,
                    major: { enabled: true },
                    font: (context) => ({
                      weight: context.tick?.major ? 500 : 400,
                    }),
                  },
                },
                y: {
                  type: "linear",
                  beginAtZero: true,
                  ticks: {
                    stepSize:
                      units?.y === LineChartUnit.percentage ? 25 : undefined,
                    autoSkipPadding: 8,
                    includeBounds: true,
                    maxRotation: 0,
                    callback: (value) => {
                      switch (units?.y) {
                        case LineChartUnit.percentage:
                          return `${value}%`;
                        case LineChartUnit.duration:
                          return formatDuration(value as number);
                        case LineChartUnit.number:
                        default:
                          return value;
                      }
                    },
                  },
                },
              },
            },
            options,
          ),
        }}
      />
    </div>
  );
}

export default forwardRef(LineChart);
