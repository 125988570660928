import styled, { css, keyframes } from "styled-components";
import Logger from "app/lib/Logger";

import Icon from "app/components/shared/Icon";
import { ComponentProps } from "react";

const VARIANTS = {
  tiny: "tiny",
  large: "large",
} as const;

type Variant = keyof typeof VARIANTS;

type Build = {
  state: string;
};

type Job = {
  type: string;
  state: string;
  passed?: boolean | null;
  softFailed?: boolean | null;
  unblockedAt?: string;
};

type Props = {
  job?: Job;
  build?: Build;
  style?: any;
  variant?: Variant;
  disableAnimation?: boolean;
  className?: string;
};

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
`;

export const colorForJob = (job: Job) => {
  switch (job.state) {
    case "finished":
      if (job.passed) {
        return "text-green-500";
      }
      return "text-red-500";
    case "running":
      return "text-yellow-500";
    default:
      return "text-charcoal-700";
  }
};

const StyledIcon = styled(Icon)`
  ${(
    props: ComponentProps<typeof Icon> & {
      disableAnimation: boolean;
    },
  ) => {
    const baseIconName = props.icon.replace(/-tiny+$|-large+$/g, "");

    if (
      !props.disableAnimation &&
      (baseIconName === "progress" || baseIconName === "clock-rainbow")
    ) {
      return css`
        will-change: transform;
        animation: ${rotate} 1.05s linear infinite;
        &:hover {
          animation-name: ${rotate}, ${rotate};
          animation-duration: 0.75s, 0.33s;
          animation-iteration-count: infinite;
          animation-delay: 0s, 5s;
          animation-timing-function: linear;
        }
      `;
    }
  }}
`;

/**
 * A wrapper on our shared Icon component that renders the correct icon for a given job or
 * build.
 *
 * @param {Job} job the job to render the icon for. Only one of `job` or `build` should be
 * provided.
 * @param {Build} build the build to render the icon for. Only one of `job` or `build`
 * should be provided.
 * @param {Variant} variant the desired size variant for this icon. Valid values are:
 * 'tiny', 'large', and may be retrieved via the VARIANTS export of the PipelineStateIcon
 * module.
 * @returns the appropriate icon for the provided job
 */
const PipelineStateIcon = ({
  job,
  build,
  style = {},
  disableAnimation = false,
  variant = VARIANTS.tiny,
  className,
}: Props) => {
  if (!job && !build) {
    return null;
  }
  if (job && build) {
    Logger.error(
      "[PipelineStateIcon] received both `job` and `build` props. Only one of these props should be provided.",
    );
  }

  let baseIconName;
  if (job) {
    baseIconName = iconForJob(job);
  } else if (build) {
    baseIconName = iconForBuild(build, variant);
  }

  if (!baseIconName) {
    return null;
  }
  const fullIconName = `${baseIconName}-${variant}`;

  return (
    <StyledIcon
      disableAnimation={disableAnimation}
      icon={fullIconName}
      style={{ fill: "none", height: 14, width: 14, ...style }}
      className={className}
    />
  );
};

const iconForJob = (job: Job) => {
  if (job.softFailed) {
    return "alert";
  }

  if (job.type === "waiter") {
    return "chevron-right";
  }

  if (job.type === "manual") {
    if (job.state === "finished" && job.unblockedAt) {
      return "chevron-right";
    }
  }

  switch (job.state) {
    case "pending":
    case "waiting":
    case "waiting_failed":
    case "limited":
    case "assigned":
    case "accepted":
      return "clock";
    case "blocked":
    case "blocked_failed":
    case "unblocked_failed":
      return "paused";
    case "unblocked":
      return "chevron-right";
    case "scheduled":
      return "clock";
    case "skipped":
      return "dash";
    case "running":
    case "canceling":
    case "timing_out":
      return "progress";
    case "canceled":
      return job.passed ? "check" : "dash-diagonal";
    case "finished":
    case "timed_out":
    case "expired":
      return job.passed ? "check" : "cross";
    case "broken":
      return "cross";
    default:
      return null;
  }
};

const iconForBuild = (build: Build, variant: Variant) => {
  switch (build.state) {
    case "failing":
    case "started":
    case "canceling":
    case "running":
      return "progress";
    case "failed":
      return "cross";
    case "canceled":
      return "dash-diagonal";
    case "passed":
      return "check";
    case "blocked":
      return "paused";
    case "creating":
      // This is a special case - the designs have two 'clock-large' icons, one for
      // 'scheduled' and one for 'waiting'. All other size variants for both states are
      // the same.
      return variant === VARIANTS.large ? "clock-rainbow" : "clock";
    case "scheduled":
      return "clock";
    case "skipped":
    case "not_run":
      return "dash";
    default:
      return null;
  }
};

export default PipelineStateIcon;
export { VARIANTS };
