import { useBuild } from "app/components/Playground/BuildContext";
import { useMemo } from "react";
import capitalize from "lodash/capitalize";

import Badge from "app/components/shared/Badge";
import { GroupStep, Step } from "app/lib/pipeline";
import EmptyState from "./EmptyState";
import { createStepListItem } from "./StepListItem";
import {
  groupStepsBy,
  filterSteps,
  useFilterStore,
  StateOrder,
  GroupByOption,
} from "./useFilterStore";
import { AnimatePresence, motion } from "framer-motion";

// Create nested steps by grouping steps by their group UUID
function createNestedSteps(steps: Step[]) {
  const stepsMap = new Map(steps.map((step) => [step.uuid, { ...step }]));

  stepsMap.forEach((step) => {
    if (step.groupUuid) {
      const group = stepsMap.get(step.groupUuid) as GroupStep;
      if (group && step.type !== "group") {
        if (!group.steps) {
          group.steps = [];
        }
        group.steps.push(step);
      }
      stepsMap.delete(step.uuid);
    }
  });

  // Remove empty groups
  return Array.from(stepsMap.values()).filter((step) => {
    if (step.type === "group" && !step.steps?.length) {
      return false;
    }
    return true;
  });
}

/**
 * Render a list of build steps.
 */
export function StepList() {
  const { build } = useBuild();
  if (!build) {
    throw new Error("Missing build context");
  }

  const filterBy = useFilterStore((state) => state.filterBy);
  const groupOption = useFilterStore((state) => state.groupOption);

  // Apply filters then group steps by group uuid
  const filteredSteps = useMemo(() => {
    const filtered = filterSteps(build.steps, filterBy, build);

    // If we are grouping by state, we don't want to group the steps
    if (groupOption === GroupByOption.State) {
      return filtered.filter((step) => step.type !== "group");
    }

    return createNestedSteps(filtered);
  }, [build.steps, filterBy, groupOption]);

  const groupedSteps = useMemo(() => {
    return groupStepsBy(filteredSteps, groupOption);
  }, [filteredSteps, groupOption]);

  if (filteredSteps.length === 0) {
    return <EmptyState />;
  }

  return (
    <div>
      {groupedSteps ? (
        <div className="flex flex-col gap-5">
          {StateOrder.map((state) => {
            const steps = groupedSteps[state];
            if (!steps) {
              return null;
            }

            return (
              <section key={state} data-testid={`${state}-group`}>
                <h2 className="my-1">
                  <Badge className="bg-white font-normal tracking-wider text-charcoal-600 text-[12px] py-0.5 px-2 m-0 uppercase">
                    {capitalize(state)}
                  </Badge>
                </h2>
                <AnimatePresence initial={false}>
                  {steps.map((step) => (
                    <motion.div
                      key={step.uuid}
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                    >
                      {createStepListItem({ step })}
                    </motion.div>
                  ))}
                </AnimatePresence>
              </section>
            );
          })}
        </div>
      ) : (
        filteredSteps.map((step) => createStepListItem({ step }))
      )}
    </div>
  );
}
