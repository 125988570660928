import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { DateLike } from "app/lib/date";
import { isJobFinished } from "app/lib/jobs";
import LegacyDispatcher from "app/lib/legacyDispatcher";

import GitCommitNotFoundWarning from "./GitCommitNotFoundWarning";
import JobLogOutput from "./Output";
import JobLogExitInformation from "./ExitInformation";
import AuthWarning from "./AuthWarning";
import DockerRateLimitedWarning from "./DockerRateLimitedWarning";

import UserSessionStore from "app/stores/UserSessionStore";

import Emojify from "app/components/shared/Emojify";
import ScrollFollower from "app/components/shared/ScrollFollower";
import Icon from "app/components/shared/Icon";

import { Job } from "app/stores/JobLogStore";
import {
  Log,
  LogBody,
  LogControl,
  LogMessage,
} from "app/components/shared/Log";
import { throttle } from "lodash";

const USER_SESSION_STORE_KEYS = {
  TIMESTAMPS_ON: "job-log-timestamps-on",
  THEME: "code-theme",
} as const;

type Props = {
  parentElementId: string;
  job: {
    id: string;
    basePath: string;
    state: string;
    passed: boolean;
    logDeletedMessage?: string;
    projectProviderId: string;
    startedAt: DateLike | null | undefined;
    agentOsId: string | null | undefined;
    agentName: string | null | undefined;
    agentVersion: string | null | undefined;
    clusterQueue?: {
      hosted?: boolean;
    };
    permissions: {
      deleteJobLog: {
        allowed: boolean;
      };
    };
  };
  build: {
    branchName: string;
    commitId: string;
  };
  autoFollow?: boolean;
};

type State = {
  following: boolean;
  theme: "default" | "solarized";
  sshKeyFailure?: boolean;
  httpsAuthFailure?: boolean;
  dockerRateLimited?: boolean;
  gitCommitNotFoundInBranchFailure?: boolean;
  linesHaveTimestamps?: boolean;
  timestampsOn?: boolean;
};

const getTheme = () => {
  const storedTheme = UserSessionStore.get(USER_SESSION_STORE_KEYS.THEME);
  return storedTheme === "default" || storedTheme === "solarized"
    ? storedTheme
    : "default";
};

export default function JobLog(props: Props) {
  const [state, setState] = useState<State>({
    following: Boolean(props.autoFollow),
    theme: getTheme(),
  });

  const disableFollow = throttle(() => {
    setState((state) => ({ ...state, following: false }));
  }, 100);

  const isTimestampsOn = useMemo(() => {
    // If timestamps aren't even available in this log, don't bother doing anythingf else
    if (!state.linesHaveTimestamps) {
      return false;
    }

    // If timestmaps haven't been manually turned on in this log, use what ever
    // they chose last.
    if (state.timestampsOn === undefined) {
      // Values are stored as strings, so we'll need to convert it
      return (
        UserSessionStore.get(USER_SESSION_STORE_KEYS.TIMESTAMPS_ON) === "true"
      );
    }

    return state.timestampsOn;
  }, [state]);

  const handleJobLogChange = useCallback(
    (log: Job) => {
      setState((state) => ({
        ...state,
        sshKeyFailure:
          log.sshKeyFailure &&
          props.job.state === "finished" &&
          !props.job.passed,
        httpsAuthFailure:
          log.httpsAuthFailure &&
          props.job.state === "finished" &&
          !props.job.passed,
        dockerRateLimited:
          log.dockerRateLimited &&
          props.job.state === "finished" &&
          !props.job.passed,
        gitCommitNotFoundInBranchFailure:
          log.gitCommitNotFoundInBranchFailure &&
          props.job.state === "finished" &&
          !props.job.passed,
        linesHaveTimestamps: log.linesHaveTimestamps,
      }));
    },
    [props.job.state, props.job.passed],
  );

  const handleThemeToggle = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      const currentTheme = getTheme();
      const newTheme = currentTheme === "default" ? "solarized" : "default";

      setState((state) => ({ ...state, theme: newTheme }));
      UserSessionStore.set(USER_SESSION_STORE_KEYS.THEME, newTheme);
    },
    [],
  );

  const handleClickFollowLog = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.preventDefault();

      setState((state) => ({ ...state, following: !state.following }));
    },
    [],
  );

  const handleClickExpandGroups = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.preventDefault();

      // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
      LegacyDispatcher.emit("job_log_group:expand_all", {
        jobId: props.job.id,
      });
    },
    [],
  );

  const handleClickCollapseLogs = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.preventDefault();

      // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
      LegacyDispatcher.emit("job_log_group:collapse_all", {
        jobId: props.job.id,
      });
    },
    [],
  );

  const handleClickToggleTimestamps = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.preventDefault();

      const timestampsOn = !isTimestampsOn;

      setState((state) => ({ ...state, timestampsOn }));

      // Store it so next time we default to that choice
      UserSessionStore.set(
        USER_SESSION_STORE_KEYS.TIMESTAMPS_ON,
        timestampsOn.toString(),
      );
    },
    [isTimestampsOn],
  );

  // If we've got multiple JobLogs open and we change the theme in one, then we should
  // make sure that all instances also switch theme. Fortunately the UserSessionStore
  // has our back, emitting an event when cached state is changed.
  UserSessionStore.on("change", (event) => {
    if (event.key === USER_SESSION_STORE_KEYS.THEME) {
      setState((state) => ({ ...state, theme: event.newValue }));
    }
  });

  if (props.job.logDeletedMessage) {
    return (
      <Log className="min-h-0 p-0" theme={state.theme}>
        <LogBody>
          <LogMessage>
            <Emojify text={props.job.logDeletedMessage} />
          </LogMessage>
        </LogBody>
      </Log>
    );
  }

  const authFailureNode = (state.sshKeyFailure || state.httpsAuthFailure) && (
    <AuthWarning
      repositoryProviderId={props.job.projectProviderId}
      osId={props.job.agentOsId}
      hostedQueue={props.job.clusterQueue?.hosted}
    />
  );

  const dockerRateLimitedNode = state.dockerRateLimited && (
    <DockerRateLimitedWarning />
  );

  const gitCommitNotFoundInBranchFailureNode =
    state.gitCommitNotFoundInBranchFailure && (
      <GitCommitNotFoundWarning
        branch={props.build.branchName}
        commit={props.build.commitId}
      />
    );

  const followLogButtonNode = isJobFinished(props.job) ? (
    <LogControl onClick={handleClickFollowLog}>
      <i className="fa fa-arrow-down" />
      Jump to end
    </LogControl>
  ) : state.following ? (
    <LogControl onClick={handleClickFollowLog} active={true}>
      <i className="fa fa-arrow-down" />
      Following…
    </LogControl>
  ) : (
    <LogControl onClick={handleClickFollowLog}>
      <i className="fa fa-arrow-down" />
      Follow
    </LogControl>
  );

  return (
    <div
      data-testid="JobLogComponent"
      className="JobLogComponent"
      onWheel={disableFollow}
    >
      {authFailureNode}
      {dockerRateLimitedNode}
      {gitCommitNotFoundInBranchFailureNode}
      <ScrollFollower following={state.following}>
        <JobLogOutput
          job={props.job}
          onChange={handleJobLogChange}
          timestampsOn={isTimestampsOn}
          theme={state.theme}
        >
          <LogControl onClick={handleClickExpandGroups}>
            <i className="fa fa-plus" />
            Expand groups
          </LogControl>

          <LogControl onClick={handleClickCollapseLogs}>
            <i className="fa fa-minus" />
            Collapse groups
          </LogControl>

          {state.linesHaveTimestamps && (
            <LogControl onClick={handleClickToggleTimestamps}>
              <i className="fa fa-calendar-o" />
              {isTimestampsOn ? "Hide timestamps" : "Show timestamps"}
            </LogControl>
          )}

          <LogControl className="lg:ml-auto" onClick={handleThemeToggle}>
            <Icon
              icon={
                state.theme === "default"
                  ? "theme-toggle-off"
                  : "theme-toggle-on"
              }
              className="h-3 w-3"
            />
            Theme
          </LogControl>

          {["finished", "canceled", "timed_out"].includes(props.job.state) &&
            props.job.permissions.deleteJobLog.allowed && (
              <LogControl
                href={props.job.basePath + "/delete_log"}
                data-method="delete"
                data-confirm="Permanently delete this job’s log?"
              >
                <i className="fa fa-trash" />
                Delete
              </LogControl>
            )}

          <LogControl href={props.job.basePath + "/download.txt"}>
            <i className="fa fa-download" />
            Download
          </LogControl>

          {!window.Features.StreamS3Logs && (
            <LogControl href={props.job.basePath + "/raw_log"} target="_blank">
              <i className="fa fa-file-o" />
              Raw
            </LogControl>
          )}

          <LogControl href={props.job.basePath + "/log"} target="_blank">
            <i className="fa fa-external-link" />
            Open
          </LogControl>

          {followLogButtonNode}
        </JobLogOutput>

        <div className="JobLogComponent__Footer py-2" style={{ fontSize: 13 }}>
          <JobLogExitInformation
            // @ts-expect-error - TS2769 - No overload matches this call.
            className="JobLogComponent__ExitStatus"
            job={props.job}
          />
          <div className="JobLogComponent__BackToTop">
            <a
              href={`#${props.parentElementId}`}
              className="JobLogComponent__BackToTop__Button"
            >
              <i className="fa fa-arrow-up" />
              Back to Job
            </a>
          </div>
        </div>
      </ScrollFollower>
    </div>
  );
}
