import Chart from "app/components/analytics/shared/Chart/";
import { DateFilter } from "app/components/analytics/shared/DateFilters";
import { Metrics } from "app/components/analytics/shared/type";
import { ChartDataset, ChartOptions as ChartJSOptions } from "chart.js";

type MiniChartMetrics = Pick<Metrics, "metric" | "chartType">;

type Props = MiniChartMetrics & {
  chartDataUrl: string;
  period: DateFilter;
};

interface ChartOptions {
  options: ChartJSOptions;
  data: {
    datasets: Omit<ChartDataset<"bar" | "line">, "data">[];
  };
}

const chartOptions = (metric: Props["metric"]) => {
  const options: ChartOptions = {
    data: {
      datasets: [
        {
          label: metric,
          // @ts-expect-error - border radius is only available in bar chart
          borderRadius: 2,
        },
      ],
    },
    options: {
      scales: {
        // eslint-disable-next-line id-length
        x: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        // eslint-disable-next-line id-length
        y: {
          beginAtZero: true,
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      layout: { padding: 1 },
      events: [], // disable all hover/click events for mini chart
    },
  };
  return options;
};

// MiniChart is a non-interactive chart component. Here we set the
// non-interactive chart options and pass them through to the chart wrapper.
const MiniChart = ({ metric, chartType, chartDataUrl, period }: Props) => (
  <Chart
    height={50}
    width={200}
    period={period}
    metric={metric}
    chartDataUrl={chartDataUrl}
    options={chartOptions(metric)}
    chartType={chartType}
  />
);

export default MiniChart;
