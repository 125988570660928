import { useMemo } from "react";
import compact from "lodash/compact";

import Dropdown from "app/components/shared/Dropdown";
import Button from "app/components/shared/Button";
import Icon from "app/components/shared/Icon";

const TAG_PARAM_NAMES = {
  filterBy: "tags",
  groupBy: "group_by_tag",
};

const TagSelectors = ({ tags }) => {
  const baseUrl = new URL(window.location.href);
  const activeTags =
    baseUrl.searchParams.get(TAG_PARAM_NAMES.filterBy)?.split(",") || [];
  const activeGroups =
    baseUrl.searchParams.get(TAG_PARAM_NAMES.groupBy)?.split(",") || [];
  const linkClassNames =
    "text-decoration-none p-1 text-xs text-charcoal-700 rounded focus:no-underline focus:text-charcoal-700 focus:outline focus:outline-2 focus:outline-lime-500 hover:text-purple-600 data-[active=true]:text-purple-600 hover:no-underline truncate";

  const generateTagUrl = (tag) => {
    let updatedTags: string[];
    const url = new URL(window.location.href);

    // Remove the execution params so the drawer does not open
    url.searchParams.delete("execution_id");
    url.searchParams.delete("run_id");

    if (activeTags.includes(tag)) {
      updatedTags = activeTags.filter((activeTag) => activeTag !== tag);
    } else {
      updatedTags = Array.from(new Set([...activeTags, ...[tag]]));
    }

    // Remove any empty strings
    updatedTags = compact(updatedTags);

    // Add the updated tags
    url.searchParams.set(TAG_PARAM_NAMES.filterBy, updatedTags.join(","));

    return url.toString();
  };

  // TODO: when we have multiple group_by tags, we can consolidate this with
  // the generateTagUrl function. Currently it is a bit different as it replaces
  // the existing param rather than adding to it.
  const generateGroupUrl = (tag) => {
    const url = new URL(window.location.href);

    // Remove the execution params so the drawer does not open
    url.searchParams.delete("execution_id");
    url.searchParams.delete("run_id");

    // Only supports adding a single group_by at the moment
    if (activeGroups.includes(tag)) {
      url.searchParams.delete(TAG_PARAM_NAMES.groupBy);
    } else {
      url.searchParams.set(TAG_PARAM_NAMES.groupBy, tag);
    }

    return url.toString();
  };

  const tagsWithUrls = useMemo(() => {
    if (tags.length === 0) {
      return [];
    }

    return tags.map(({ key, key_value }) => ({
      key,
      key_value,
      removeTagUrl: generateTagUrl(key_value),
      removeGroupUrl: generateGroupUrl(key),
    }));
  }, [tags]);

  return (
    <div className="flex flex-wrap items-center gap1 w-full">
      {tagsWithUrls.map((tag) => {
        const activeTag = activeTags.includes(tag.key_value);
        const activeGroup = activeGroups.includes(tag.key);
        return (
          <Dropdown key={tag.key_value} className="flex">
            <Button
              theme="small"
              data-active={activeTag || activeGroup}
              className="text-xs h-auto py-0.5 px-2 data-[active=true]:text-purple-600"
            >
              {tag.key_value}
              <Icon
                icon="down-triangle"
                style={{ width: 8, height: 8, marginLeft: "5px" }}
              />
            </Button>

            <div className="p-2 flex flex-column gap-1">
              <a
                href={tag.removeTagUrl}
                data-active={activeTag}
                className={linkClassNames}
              >
                {activeTag ? "Remove filter: " : "Filter by: "}
                <strong>{tag.key_value}</strong>
              </a>
              <a
                href={tag.removeGroupUrl}
                data-active={activeGroup}
                className={linkClassNames}
              >
                {activeGroup ? "Remove group: " : "Group by: "}
                <strong>{tag.key}</strong>
              </a>
            </div>
          </Dropdown>
        );
      })}
    </div>
  );
};

export default TagSelectors;
